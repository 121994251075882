/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

html {
  scroll-behavior: smooth;
}

@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

.type {
  height: 100px;
  padding-top: 1.3rem;
  padding-bottom: 0px;
}


.index-module_type__E-SaG::after {
  content: '|' !important;
  color: #fff !important;
  animation: animated-text 4s linear 1s 1 normal both,
    animated-cursor 600ms linear infinite !important;
}

/* text animation */

@keyframes animated-text {
  from {
    width: 0;
  }

  to {
    width: 456px;
  }
}

/* cursor animations */

@keyframes animated-cursor {
  from {
    border-right-color: #fff;
  }

  to {
    border-right-color: transparent;
  }
}


.react-viewer-close {
  margin-top: 80px !important;
}
.react-viewer-canvas{
  margin-top: 60px !important;

}


.form-control::-moz-placeholder{color:#3a3a3a;opacity:1}
.form-control:-ms-input-placeholder{color:#3a3a3a}
.form-control::-webkit-input-placeholder{color:#3a3a3a}